@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16x;
  color: #777777;
  background-color: #F7F7F7;
}
.custom-splide {
  padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.editor-container {
  margin-bottom: 0px !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  line-height: 40.96px;
}

.h-banner {
  min-height: 50vh;
  max-height: 50vh;
}

.h-banner-large {
  min-height: 60vh;
  max-height: 60vh;
}

.h-banner-small {
  min-height: 40vh;
  max-height: 40vh;
}

@media (max-width: 576px) {
  .h-banner {
    min-height: 45vh;
    max-height: 45vh;
  }

  .h-banner-large {
    min-height: 45vh;
    max-height: 45vh;
  }

  .h-banner-small {
    min-height: 40vh;
    max-height: 40vh;
  }
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

.footer {
  background: transparent radial-gradient(closest-side at 50% 50%, #F8B03A 0%, #E6B139 0%, #E3812A 100%) 0% 0% no-repeat padding-box;
}

.bg-glassmorphism {
  background: transparent linear-gradient(180deg, #FFD5AF 0%, #E6B139 100%) 0% 0% no-repeat padding-box;
}

.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: 45%;
	right: auto;
  top: -20px;
	bottom: auto;
	border: 10px solid;
	border-color: #E3832B transparent transparent transparent;
  rotate: 180deg;
}

.button-glassmorphism {
  background: transparent linear-gradient(175deg, #FFD5AF 0%, #E6B139 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px #00000029;
  opacity: 1;
}

.w-fill {
  width: -webkit-fill-available !important;
}

.ovale{
  border-radius: 48% 52% 61% 39% / 41% 41% 59% 59% ;
}

.text-hl {
  color: #F59F3D;
}

.card-gradient {
  background: transparent linear-gradient(150deg, #E6B139 0%, #F8B03A 100%) 0% 0% no-repeat;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.splide__arrow svg{
  fill: #E3812A !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

button.video-react-button.video-react-big-play-button.video-react-big-play-button-left {
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -1.5em;
}

.editor-shell {
  margin: auto !important;
}

.toolbar {
  position:relative;
  height: 50px !important;
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.toolbar::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
.toolbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.toolbar::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 50px;
}

/* Handle on hover */
.toolbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.character-style-popup {
    height: 45px !important;
}

textarea::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
  background: rgba(119, 119, 119, 0.2);
  border-radius: 50px;
}

.collection-stat svg {
  transform: rotate(-90deg);
}

.progress-bar__background {
  fill: none;
  stroke: #EEE9E0;
  stroke-width: 2;
}

.progress-bar__progress {
  fill: none;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: 2;
  transition: stroke-dashoffset 1s ease-in-out;
}

.editor-container ul, ol {
  list-style: auto;
}

.safari {
  --tw-shadow: 0 2.75rem 3.5rem -2rem rgb(45 55 75 / 20%), 0 0 5rem -2rem rgb(45 55 75 / 15%);
  --tw-shadow-colored: 0 2.75rem 3.5rem -2rem var(--tw-shadow-color), 0 0 5rem -2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.pet::before {
  background: url("../images/pets.png")
}

.count-up {
  border: 6px solid var(--unnamed-color-707070);
  background: transparent linear-gradient(0deg, #575756 0%, #575756 15%, #1D1D1B 26%, #000000 100%) 0% 0% no-repeat padding-box;
  border: 6px solid #707070;
  border-radius: 4px;
  opacity: 1;
}

.koudpouss-player video {
  overflow: hidden;
  border-radius: 24px;
}

.koudpouss-player .video-react {
  height: 200px !important;
}

@media (min-width: 768px) {
  .koudpouss-player .video-react {
    height: 400px !important;
  }
}

/* Custom range */

input[type="range"] {
  -webkit-appearance: none;
  border-radius: 10px;
  padding: 5px;
  outline:none;
  position: relative;
  box-sizing:border-box;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  background: url("../images/heart_icon.png") center/contain no-repeat content-box;
  z-index: 1;
  width: 50px;
  position: relative;
  height: 50px;
  padding: 5px;
}

/* Rotation uniquement pour la version desktop */
@media (min-width: 640px) {
  input[type="range"]::-webkit-slider-thumb {
    transform: rotate(90deg);
  }
}

/* Version mobile - pas de rotation */
@media (max-width: 639px) {
  input[type="range"]::-webkit-slider-thumb {
    transform: rotate(0deg);
  }
}

/* Ajout du support pour Firefox */
input[type="range"]::-moz-range-thumb {
  border-radius: 50%;
  background: url("../images/heart_icon.png") center/contain no-repeat content-box;
  z-index: 1;
  width: 50px;
  position: relative;
  height: 50px;
  padding: 5px;
}

@media (min-width: 640px) {
  input[type="range"]::-moz-range-thumb {
    transform: rotate(90deg);
  }
}

@media (max-width: 639px) {
  input[type="range"]::-moz-range-thumb {
    transform: rotate(0deg);
  }
}

/* Styles pour React-Quill */
.editor-container {
  margin-bottom: 40px; /* Pour laisser de l'espace pour la toolbar */
}

.editor-container .ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.editor-container .ql-container.ql-snow {
  border: 1px solid #ccc;
  min-height: 200px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.editor-container .ql-editor {
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.42;
}

.editor-container .ql-editor p,
.editor-container .ql-editor ol,
.editor-container .ql-editor ul,
.editor-container .ql-editor pre,
.editor-container .ql-editor blockquote {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.editor-container .ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}

.editor-container .ql-snow .ql-toolbar button:hover {
  color: #06c;
}

.editor-container .ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

/* Styles pour les images dans l'éditeur */
.editor-container .ql-editor img {
  max-width: 100%;
  height: auto;
}

/* Styles pour mobile */
@media (max-width: 640px) {
  .editor-container .ql-snow .ql-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .editor-container .ql-snow .ql-formats {
    margin-right: 5px;
  }
}


.custom-slider-bullet {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 1.5px;
}

.custom-slider-bullet::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 2px solid currentColor;
  cursor: pointer;
}

.custom-slider-bullet::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 2px solid currentColor;
  cursor: pointer;
}